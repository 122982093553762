import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import soClosePath from '../assets/images/so_close.gif'
import HeaderGeneric from '../components/HeaderGeneric'

const NotFoundPage = () => (
  <Layout>
    <Helmet title="Digital Tumbleweed: Not Found" />

    <div id="main">
      <img src={soClosePath} alt="" className="header" />

      <HeaderGeneric title="We couldn't find the page you were looking for." />

      <section className="main">
        <p>
          Please head back to the{' '}
          <a href="/" title="homepage">
            homepage
          </a>{' '}
          and try again.
        </p>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
